import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import { LineHeader, Title, Semi } from "components/Module/Module";
import { Pill, PillFilter } from "components/Pill/Pill";
import EditorialGrid from "modules/EditorialGrid/EditorialGrid";
import { SectionMargin } from "components/Section/Section";
import MoonCycle from "components/MoonCycle/MoonCycle";
import { ModuleNewsletter } from "modules/Newsletter/Newsletter";
import GraphQLErrorList from "components/graphql-error-list";
import SEO from "components/seo";
import Layout from "containers/layout";
import { getValuesFromQuery } from "utils/filter";
import useFilter from "utils/useFilter";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";

export const query = graphql`
  query EditorialsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    editorials: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          _rawDisplayTitle
          mainImage {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          publishedAt
          categories {
            title
          }
        }
      }
    }
  }
`;

const FiltersWrapper = styled.div`
  position: relative;
  max-height: ${(props) => (props.toggle ? "300px" : "0")};
  overflow: hidden;
  transition: max-height 0.2s ease;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  ${media.large` 
    max-heighT: unset;
    width: auto;
    margin-left: 0;
    transform: translateX(0);
  `}
`;

const FilterMobileLabel = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  text-transform: uppercase;
  color: var(--brand-grey2);
  margin: 40px 0 20px;
  box-sizing: border-box;
  ${media.large` 
    display: none;
  `}
`;

const Filters = styled.div`
  display: flex;
  padding: 0 15px 0 15px;
  overflow-x: scroll;

  ${media.large` 
    padding: 0;
    overflow-x: hidden;
  `}
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin: 0 10px 0 0;
    ${media.large` 
      margin: 0 40px 0 0;
    `}
  }
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;

  & > div {
    display: block;
  }

  ${media.large` 
    & > div {
      display: flex;
    } 
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MobileFilterButton = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  text-transform: uppercase;
  color: var(--brand-grey2);
  text-decoration: underline;

  ${media.large` 
    display: none;
  `}
`;

export async function getServerData({ query, ...rest }) {
  const { getFilterResults } = require("../utils/filter");
  const data = await getFilterResults({
    query,
  });

  return {
    props: {
      query,
      data,
    },
  };
}

const LibraryPage = (props) => {
  const { data, serverData, errors } = props;
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(serverData.query);
  const [filters, setFilters] = React.useState(queryParams);
  const [filterToggle, setFilterToggle ] = React.useState(false);
  const [ showMoonCycle, setShowMoonCycle ] = React.useState(false);
  const initialFilters = React.useMemo(() => queryParams, []);
  const timer = React.useRef();
  const primed = React.useRef(false);
  const { posts, isFetching } = useFilter(
    filters,
    serverData.data.posts,
    false,
    initialFilters,
    "posts"
  );

  React.useEffect(() => {
    if (isFetching) {
      primed.current = true;
      setShowMoonCycle(true);
    } else if(primed.current) {
      setTimeout(()=> { 
        primed.current = false;
        setShowMoonCycle(false);
      }, 1000);
    }
  }, [isFetching]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const getCategoryFilterValue = (data, changed) => {
    setFilters((filters) => ({ ...filters, categories: data }));
  };

  const getMediumFilterValue = (data, changed) => {
    setFilters((filters) => ({ ...filters, medium: data }));
  };

  const isChecked = (value, filterArray) => {
    return filterArray ? filterArray.includes(value) : false;
  };

  return (
    <Layout
      pageElements={
        <CustomLineHeader>
          <TitleWrapper>
            <Title>THE NEW MOON <Semi>LIBRARY</Semi></Title>
            <MobileFilterButton onClick={() => setFilterToggle(!filterToggle)}>
              Filter
            </MobileFilterButton>
          </TitleWrapper>
          <FiltersWrapper toggle={filterToggle}>
            <Filters>
              <Filter>
                <FilterMobileLabel>filter by</FilterMobileLabel>
                <PillFilter label="filter by" getValues={getCategoryFilterValue}>
                  <Pill
                    name="categories"
                    value="Mind"
                    checked={isChecked("Mind", initialFilters.categories)}
                  >
                    mind
                  </Pill>
                  <Pill
                    name="categories"
                    value="Body"
                    checked={isChecked("Body", initialFilters.categories)}
                  >
                    body
                  </Pill>
                  <Pill
                    name="categories"
                    value="Life"
                    checked={isChecked("Life", initialFilters.categories)}
                  >
                    life
                  </Pill>
                  <Pill
                    name="categories"
                    value="Spirit"
                    checked={isChecked("Spirit", initialFilters.categories)}
                  >
                    spirit
                  </Pill>
                </PillFilter>
              </Filter>
              <Filter>
                <FilterMobileLabel>medium</FilterMobileLabel>
                <PillFilter label="medium" getValues={getMediumFilterValue}>
                  <Pill
                    name="medium"
                    value="read"
                    checked={isChecked("read", initialFilters.medium)}
                  >
                    read
                  </Pill>
                  <Pill
                    name="medium"
                    value="watch"
                    checked={isChecked("watch", initialFilters.medium)}
                  >
                    watch
                  </Pill>
                  <Pill
                    name="medium"
                    value="listen"
                    checked={isChecked("listen", initialFilters.medium)}
                  >
                    listen
                  </Pill>
                </PillFilter>
              </Filter>
            </Filters>
          </FiltersWrapper>
        </CustomLineHeader>
      }
    >
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      { showMoonCycle && <MoonCycle /> }
      {posts && !showMoonCycle ? (
        <>
          <EditorialGrid nodes={posts.filter((node, i) => i < 6) || null} />
          <ModuleNewsletter />
          <EditorialGrid nodes={posts.filter((node, i) => i >= 6) || null} />
        </>
      ) : null}
    </Layout>
  );
};

export default LibraryPage;
