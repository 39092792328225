import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  Square,
  Portrait,
} from "modules/EditorialSummary/EditorialSummary";
import Grid from "components/Grid/Grid";
import { SectionMargin, PadSection } from "components/Section/Section";
import { getBlogUrl } from "lib/helpers";
import { media } from "utils/mediaQuery";

const LargeCol = styled.div`
  grid-column: span 6;
`;

const SmallCol = styled.div`
  grid-column: span 3;
`;

const Wrapper = styled(PadSection)`
  margin: 50px auto;
`;

const DesktopGrid = styled(Grid)`
  display: none;
  ${media.large` 
    display: grid;
  `}
`;

const MobileGrid = styled(Grid)`
  display: grid;
  ${media.large` 
    display: none;
  `}
`;

const EditorialGrid = ({ nodes, gatsby, ...rest }) => {
  console.log(nodes)
  return (
    <Wrapper {...rest}>
      <MobileGrid>
        {nodes &&
          nodes.map((node, i) => {
            return (
              <LargeCol key={i}>
                <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                  <Square
                    key={node.slug.current}
                    displayTitle={!gatsby ? node.displayTitle: node._rawDisplayTitle}
                    category={node.categories[0].title || null}
                    sanityImage={!gatsby ? node.mainImage.asset : null}
                    image={gatsby ? node.mainImage.asset : null}
                  />
                </Link>
              </LargeCol>
            );
          })}
      </MobileGrid>
      <DesktopGrid>
        {nodes &&
          nodes.map((node, i) => {
            const currentRow = Math.floor(i / 3);

            const conditional = {
              0: () => {
                return i % 3 === 0
              },
              1: () => {
                return i % 3 === 2
              },
            }

            if (conditional[currentRow % 2]()) {
              return (
                <LargeCol>
                  <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                    <Square
                      key={node.slug.current}
                      displayTitle={!gatsby ? node.displayTitle: node._rawDisplayTitle}
                      category={node.categories[0].title || null}
                      sanityImage={!gatsby ? node.mainImage.asset : null}
                      image={gatsby ? node.mainImage.asset : null}
                    />
                  </Link>
                </LargeCol>
              );
            }

            return (
              <SmallCol>
                <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                  <Portrait
                    key={node.slug.current}
                    displayTitle={!gatsby ? node.displayTitle: node._rawDisplayTitle}
                    category={node.categories[0].title || null}
                    sanityImage={!gatsby ? node.portraitImage.asset : null}
                    image={gatsby ? node.portraitImage.asset : null}
                  />
                </Link>
              </SmallCol>
            );
          })}
      </DesktopGrid>
    </Wrapper>
  )
};

export default EditorialGrid;
