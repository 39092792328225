import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LazyBlurImage } from "@manualengineering/react-lazyblur";
import PortableText from "components/portableText";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";
import { Arrow } from "components/Iconography/Iconography";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: relative;
  background: transparent;
  overflow: hidden;
  padding: 0 0 70%;
`;

const PortraitImageWrapper = styled(ImageWrapper)`
  padding: 0 0 calc(140% + 12px);
`;

const ImageInner = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;

  & > div {
    padding-bottom: 0 !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  background: var(--brand-white);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  flex: 1;
  padding: 15px;

  ${media.large` 
    padding: 15px 20px 20px 15px;
  `}

  ${media.xxlarge` 
    padding: 20px 25px 20px 25px;
  `}
`;

const CategoryName = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey3);
  text-transform: uppercase;

  ${media.large` 
    ${type("subheaderC")}
  `}
`;

const ContentInnerWrapper = styled.div`
  grid-column: 1 / span 5;
`;

const Title = styled.h5`
  ${fontstack.primary}
  ${type("body1")}
  color: var(--brand-primary);
  margin: 0;
  transition: color 0.2s ease;

  ${media.large` 
    ${type("titleA")}
  `}

  p {
    margin: 0;
  }

  &:hover {
    color: var(--brand-grey2);
  }
`;

const ArrowWrapper = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ArrowIcon = styled(Arrow)`
  width: 10px;

  ${media.large` 
    width: 13px;
  `}
`;

const Image = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const SanityImage = styled(LazyBlurImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const Square = ({ title, displayTitle, category, image, sanityImage, portrait, ...rest }) => {
  const imageObject = image ? getImage(image) : null;

  const animationProps = {
    whileHover: {
      scale: 1.04,
    },
    transition: {
      duration: 0.4,
    },
  };

  return (
    <Wrapper {...rest}>
      {portrait ? (
        <PortraitImageWrapper>
          <ImageInner {...animationProps}>
            {imageObject ? (
              <Image image={imageObject} objectFit="cover" />
            ) : sanityImage ? (
              <SanityImage
                src={`${sanityImage.url}?w=1000&q=80`}
                placeholder={{
                  src: sanityImage.metadata.lqip,
                  width: sanityImage.metadata.dimensions.width,
                  height: sanityImage.metadata.dimensions.height,
                }}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            ) : null}
          </ImageInner>
        </PortraitImageWrapper>
      ) : (
        <ImageWrapper>
          <ImageInner {...animationProps}>
            {imageObject ? (
              <Image image={imageObject} objectFit="cover" />
            ) : sanityImage ? (
              <SanityImage
                src={`${sanityImage.url}?w=1000&q=80`}
                placeholder={{
                  src: sanityImage.metadata.lqip,
                  width: sanityImage.metadata.dimensions.width,
                  height: sanityImage.metadata.dimensions.height,
                }}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            ) : null}
          </ImageInner>
        </ImageWrapper>
      )}
      <ContentWrapper>
        <ContentInnerWrapper>
          <CategoryName>{category}</CategoryName>
          <Title>
            {displayTitle ? (
              <PortableText blocks={displayTitle} />
            ) : title ? (
              title
            ) : null}
          </Title>
        </ContentInnerWrapper>
        <ArrowWrapper>
          <ArrowIcon />
        </ArrowWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export const Portrait = (props) => {
  const { ...rest } = props;
  return <Square {...rest} portrait />;
};