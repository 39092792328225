import React, { useState } from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const PillWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin: 0 10px 0 0;
  }
`;

const LabelWrapper = styled.label`

`

const Checkbox = styled.input`
  display: none;
`;

const Label = styled.div`
  cursor: pointer;
  ${fontstack.secondary}
  ${type("subheaderC")}
  line-height: 13px;
  color: var(--brand-grey2);
  text-transform: uppercase;
  padding: 5px 11px;
  border: 1px solid var(--brand-grey3);
  border-radius: 20px;
  transition: all 0.2s ease;
  user-select: none;

  ${media.large` 
    ${type("subheaderB")}
    line-height: 16px;
  `}

  &:hover {
    ${media.large` 
      color: var(--brand-black);
      border: 1px solid var(--brand-black);
    `}
  }

  ${Checkbox}:checked + & {
    color: var(--brand-white);
    border: 1px solid var(--brand-grey1);
    background: var(--brand-grey1);
  }
`;

export const Pill = (props) => {
  const { children, className, ...rest } = props;

  return (
    <PillWrapper className={className}>
      <LabelWrapper>
        <Checkbox type="checkbox" {...rest} />
        <Label>{children}</Label>
      </LabelWrapper>
    </PillWrapper>
  );
};



const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterLabel = styled.div`
  ${fontstack.secondary}
  ${type("subheaderB")}
  line-height: 16px;
  color: var(--brand-grey2);
  text-transform: uppercase;
  margin: 0 10px 0 0;
  display: none;

  ${media.large` 
    display: block;
  `}
`;

export const PillFilter = ({children, getValues, label}) => {
  const [checkedPills, setCheckedPills] = useState(
    React.Children.map(children, (child) => {
      if (
        React.isValidElement(child) &&
        child.props.checked &&
        child.props.value
      ) {
        return child.props.value;
      }

      return false;
    })
  );

  const onChange = (position, value) => {
    let changedValue = {};
    const updatedCheckedState = checkedPills.map((item, index) => {
      if (index === position) {
        if (item) {
          changedValue[value] = false;
        } else {
          changedValue[value] = true;
        }
        return item ? false : value;
      }
      return item;
    });

    setCheckedPills(updatedCheckedState);

    getValues &&
      getValues(
        updatedCheckedState.filter((item) => item !== false),
        changedValue
      );
  };

  const pills = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        id: index,
        checked: checkedPills[index],
        onChange: () => onChange(index, child.props.value),
      });
    }
    return child;
  });

  return <FilterWrapper><FilterLabel>{label}</FilterLabel>{pills}</FilterWrapper>;
};
